import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const overview_img = data.overview_img?.childImageSharp?.fluid
  const implants_img = data.implants_img?.childImageSharp?.fluid
  const dental_implants_img = data.dental_implants_img?.childImageSharp?.fluid
  const general_dentist_img = data.general_dentist_img?.childImageSharp?.fluid
  const root_canal_expect_img = data.root_canal_expect_img?.childImageSharp?.fluid
  const filling_in_the_gaps = data.filling_in_the_gaps?.childImageSharp?.fluid
  const preventive_dentistry_img = data.preventive_dentistry_img?.childImageSharp?.fluid
  const oral_health = data.oral_health?.childImageSharp?.fluid
  const dental_services = data.dental_services?.childImageSharp?.fluid
  const dental_implant_question_img = data.dental_implant_question_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Blog - The Dentists At Gateway Crossing</title>
      <meta name="description" content="Check out the Dentists at Gateway Crossing blog to stay up to date on dental services and topics." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Check out the Dentists at Gateway Crossing blog to stay up to date on dental services and topics." />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Check out the Dentists at Gateway Crossing blog to stay up to date on dental services and topics." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
    <BackgroundImage
      Tag="section"
      className="hero-section-bg"
      fluid={hero_background}
      backgroundColor={`#e9f4f8`}
      alt="Our Office - The Dentists at Gateway Crossing"
    >
        <div className ="col-lg-12" id ="color-overlay"> </div>
        <section id="hero-section" className="blog-page-hero">
          <Container>
            <div className="text-center col-lg-12">
              <h1 className="text-white">Blog</h1>
            </div>
          </Container>
        </section>
      </BackgroundImage>
      <section id="blog-section">
        <Container>
          <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={overview_img} className="blogpost-img"/>
              <div className="blog-description">
              <div className="blog-title">
                  <h3>Overview of Dental Implants.</h3>
                </div>
                <div className="description-container">
                  <p>
                    Dental implants are biocompatible structures that a dentist
                    inserts into the jawbone to act as an artificial root.
                  </p>
                </div>
                <Link to="/overview-of-dental-implants">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

        <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={implants_img} className="blogpost-img"/>
              <div className="blog-description">
              <div className="blog-title">
                  <h3>Are Dental Implants Worth it?</h3>
                </div>
                <div className="description-container">
                  <p>
                    Dental implants are a common treatment for missing teeth.
                  </p>
                </div>
                <Link to="/are-dental-implants-worth-it">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={dental_implants_img} className="blogpost-img"/>
              <div className="blog-description">
                <div className="blog-title">
                  <h3>Differences Between Dental Implants and Dentures.</h3>
                </div>
                <div className="description-container">
                  <p>
                    Do you know which tooth replacement options are right for
                    you?
                  </p>
                </div>
                <Link to="/differences-between-dental-implants-and-dentures">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={general_dentist_img} className="blogpost-img"/>
              <div className="blog-description">
              <div className="blog-title">
                  <h3>General Dentists and the Services They Provide</h3>
                </div>
                <div className="description-container">
                  <p>
                    General dentists are qualified to provide a wide range of
                    dental services for patients.
                  </p>
                </div>
                <Link to="/general-dentists-services-provided">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={root_canal_expect_img} className="blogpost-img"/>
              <div className="blog-description">
              <div className="blog-title">
                  <h3>Root Canal: What to Expect</h3>
                </div>
                <div className="description-container">
                  <p>
                    Root canals are often needed when a cavity has been left
                    untreated.
                  </p>
                </div>
                <Link to="/what-to-expect-root-canal">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={filling_in_the_gaps} className="blogpost-img"/>
              <div className="blog-description">
              <div className="blog-title">
                  <h3>Filling in the Gaps About Cosmetic Dentistry</h3>
                </div>
                <div className="description-container">
                  <p>
                  If you have broken or damaged teeth, and are perhaps ashamed of your smile.
                  </p>
                </div>
                <Link to="/filling-in-the-gaps-about-cosmetic-dentistry">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={preventive_dentistry_img} className="blogpost-img"/>
              <div className="blog-description">
              <div className="blog-title">
                  <h3>Everything You Need To Know About Preventive Dentistry</h3>
                </div>
                <div className="description-container">
                  <p>
                    In this post we will be going over preventive dentistry and what it means for you as a patient. First of all, what is it?
                  </p>
                </div>
                <Link to="/everything-you-need-to-know-about-preventive-dentistry">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={oral_health} className="blogpost-img"/>
              <div className="blog-description">
              <div className="blog-title">
                  <h3>Stay Ahead Of Your Oral Health In 2022</h3>
                </div>
                <div className="description-container">
                  <p>
                  The start of a new year is an excellent time to make improvements in your life and set new goals that you should carry...
                  </p>
                </div>
                <Link to="/stay-ahead-of-your-oral-health-in-2022">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={dental_services} className="blogpost-img"/>
              <div className="blog-description">
              <div className="blog-title">
                  <h3>7 Services That Your Dentist Should Provide</h3>
                </div>
                <div className="description-container">
                  <p>
                  Dental treatment required at a dental clinic is determined by the condition of your teeth...
                  </p>
                </div>
                <Link to="/7-services-that-your-dentist-should-provide">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={dental_implant_question_img} className="blogpost-img"/>
              <div className="blog-description">
              <div className="blog-title">
                  <h3>8 Questions to Ask Your Dentist About Dental Implants</h3>
                </div>
                <div className="description-container">
                  <p>
                    Have you had your dentist recommend dental implants to repair a lost tooth?
                  </p>
                </div>
                <Link to="/eight-questions-to-ask-your-dentist-about-dental-implants">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          {/*}<div className="col-lg-4">
            <div className="blog-container">
              <Img fluid={overview_img} className="blogpost-img"/>
              <div className="blog-description">
                <h3>
                  Overview of dental implants
                </h3>
                <div className="description-container">
                  <p>
                    Dental implants are biocompatible structures that a dentist inserts into the jawbone to act as an artificial root.
                  </p>
                </div>
                <Link to="/blog-post1">
                  <button className="btn-rounded">Read More</button>
                </Link>
              </div>
            </div>
          </div> */}
        </Container>
      </section>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query BlogPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    overview_img: file(name: { eq: "overview-blog" }) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    implants_img: file(name: { eq: "dental-implants-blog" }) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dental_implants_img: file(name: { eq: "dental-implants-blog3" }) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    general_dentist_img: file(name: { eq: "general-dentists-services-provided" }) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    root_canal_expect_img: file(name: { eq: "dental-pain" }) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    filling_in_the_gaps: file(name: { eq: "implant-in-lower-jawbone" }) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    preventive_dentistry_img: file(name: { eq: "cleanings-and-preventive-care" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    oral_health: file(name: { eq: "Oral-health-in-2022" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dental_services: file(name: { eq: "dental-services" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dental_implant_question_img: file(name: { eq: "man-smiling-about-dental-implants" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
